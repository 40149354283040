import PropTypes from "prop-types"
import React from "react"

import MainNav from "./mainNav"

import logo from "../images/logo-kaleb.svg"


const Header = ({ siteTitle }) => (
  <header  class="mb-16 lg:mb-40 py-4 px-5 bg-texture border border-b shadow">
    <div style={{
        margin: `0 auto`,
        maxWidth: 1066
      }}>
      <div class="flex flex-row justify-between items-center">
        <span class="flex flex-row items-center">
          <img class="m-0" src={logo} alt=""/> <span class="ml-3 uppercase font-bold text-gold text-base tracking-wider invisible lg:visible xl:visible">Kaleb Jimenez</span> 
        </span>
        <MainNav/>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
