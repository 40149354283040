import React from "react"
import MainNavItem from "./mainNavItem"

const MainNav = () => (
  <nav>
      <ul class="flex flex-row m-0">
          <MainNavItem title="About" link="#about"/>
          <MainNavItem title="Process" link="#process"/>
          <MainNavItem title="Work" link="#work"/>
          <MainNavItem title="Contact" link="#contact"/>
      </ul>
  </nav>
)


export default MainNav