import React from "react"
import { Link } from "gatsby"


const MainNavItem = (props) => (
  <li class="ml-3 mb-0 uppercase hover:text-gold font-medium text-gray text-sm transition-colors duration-500">
      <Link to={props.link}>{props.title}</Link>
  </li>
)


export default MainNavItem